import { loadDatadog } from '../services/datadog'
import { loadIntercom } from '../services/intercom.js'
import { loadOnetrust } from '../services/onetrust'

const searchParams = new URLSearchParams(window.location.search)
if (searchParams.get('no-cookies') !== 'true') {
  loadOnetrust()
}
loadIntercom()
loadDatadog()
