import { environmentVariables } from '../utils/environmentVariables'

// OneTrust is our cookie banner tool.
// See https://www.notion.so/swile/Integrate-One-Trust-in-your-ReactJs-application-79d2f8f17c3c4e2385f77c4b1859dc8a

const SCRIPT_ID = environmentVariables('ONETRUST_DOMAIN_SCRIPT_ID')

const DISABLED = environmentVariables('ONETRUST_ENABLED') === 'false'

export const loadOnetrust = (): void => {
  if (DISABLED) {
    return
  }
  const script = document.createElement('script')
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  script.type = 'text/javascript'
  script.setAttribute('data-domain-script', SCRIPT_ID)
  document.head.appendChild(script)
}
