import { datadogLogs } from '@datadog/browser-logs'

import { environment } from '../utils/environmentVariables'

export const loadDatadog = (): void => {
  datadogLogs.init({
    clientToken: 'pub04930f573682625d230dafa5adc7b750',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'directory-front',
    env: environment,
  })
}

const shouldLogLocalStorageKey = 'swile-log-to-datadog'

export const logInfo = (message: string, messageContext?: object, error?: Error): void => {
  if (localStorage.getItem(shouldLogLocalStorageKey) === 'true') {
    datadogLogs.logger.info(message, messageContext, error)
  }
}

export const logWarn = (message: string, messageContext?: object, error?: Error): void => {
  if (localStorage.getItem(shouldLogLocalStorageKey) === 'true') {
    datadogLogs.logger.warn(message, messageContext, error)
  }
}

export const logError = (message: string, messageContext?: object, error?: Error): void => {
  if (localStorage.getItem(shouldLogLocalStorageKey) === 'true') {
    datadogLogs.logger.error(message, messageContext, error)
  }
}
